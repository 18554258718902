<script>
import { onMounted, onUnmounted, ref, watch, inject } from 'vue'
import { signIn } from '@/http/api'
import countDown from '@/components/countdown'
import md5 from 'md5'
import router from '@/routes'
import { http } from '@/http'
import { needLoading, userAuth } from '@/reactive'
import { Toast } from 'vant'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup() {
    let tel = ref('')
    let password = ref('')
    let code = ref('')
    let checked = ref(false)
    let clickable = ref(false)
    let slogan = ref('')
    let serviceTel = inject('serviceTel')
    let route = useRoute()
    let router = useRouter()

    const onSubmit = (fields) => {
      if(!checked.value) {
        Toast('请勾选同意后再进行注册')
        return false
      }
      if(needLoading.value) {
        return false
      }
      needLoading.value = true
      
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerLoginService.brokerReg', {
        tel: fields.tel,
        loginPwd: md5(fields.password),
        smsCode: fields.code,
        referrerRole: route.query.referrerRole,
        referrerId: route.query.referrerId
      }).then(res => {
        if(res.result) {
          signIn({
            tel: fields.tel,
            loginPwd: md5(fields.password)
          }).then(data => {
            if(data.result) {
              Toast.success('登录成功!')
              userAuth.value = {
                ...userAuth,
                ...data.result
              }
              window.localStorage.setItem('userAuth', JSON.stringify(data.result))
              if(data.result.showStatus === 'INDEX'){
                router.push('/')
              }
              else if(data.result.showStatus === 'REAL_AUTH') {
                router.push('/verified')
              }
              else if(data.result.showStatus === 'AGRT_SURE') {
                router.push('/trust?type=broker')
              }
              else if(data.result.showStatus === 'SERVICE_OPEN') {
                router.push('/open-service')
              }
            }
        
          }).catch(err => {})
        }
      })
    }


    const handleSendCode = (a) => {
      if(!clickable.value) {
        Toast('请输入正确的手机号')
        return false
      }
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerLoginService.brokerCaptchaVerify', {
        templateCode: 'SMS_BROKER_LOGIN_REGIST',
        smsPhoneNumber: tel.value
      }).then(res => {
        if(res.result) {
          //
        }
        else {
          a.value = Date.now()
        }
      })
      // sendCode({
      //   templateCode: 'SMS_MERCHANT_LOGIN_REGIST',
      // }).then(data => {
        
      // })
    }

    watch(() => tel.value, (a, b) => {
      clickable.value = /^1[3456789][0-9]{9}$/.test(a)
    })

    onMounted(() => {
      http.post('/?cmd=com.xqxc.api.pigear.biz.platform.KefuTelService.getSlogn').then(res => {
        slogan.value = res.result
      })
    })

    return {
      onSubmit,
      tel,
      code,
      password,
      checked,
      clickable,
      slogan,
      handleSendCode,
      serviceTel
    }
  },
  components: {
    countDown,
  }
}
</script>
<template>
  <div class="page">
    <van-form @submit="onSubmit"  validate-trigger="onSubmit" >
      <!-- <h3>注册猪耳朵渠道商账号<p class="slogan">{{slogan}}</p></h3> -->
      <h3>注册猪耳朵渠道商账号<p class="slogan">猪耳朵省时，省力，更省钱，聚合平台领跑者</p></h3>
      <van-field
        v-model="tel"
        name="tel"
        maxlength="11"
        type="number"
        autocomplete="off"
        placeholder="请输入手机号码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{
          required: true,
          message: '请输入手机号码' 
        }, {
          pattern: /^1[3456789][0-9]{9}$/, 
          message: '请输入正确手机号格式'
        }]"
      />
      <van-field
        v-model="code"
        label-width="1rem"
        name="code"
        maxlength="4"
        autocomplete="off"
        type="number"
        placeholder="请输入4位数字验证码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{
          required: true,
          message: '请输入验证码' 
        }
        ]"
      >
        <template #extra>
          <countDown 
            :clickControlled="clickable" 
            @handleSend="handleSendCode"
          >
            <span class="vcode">发送验证码</span>
          </countDown>
        </template>
      </van-field>
      <van-field
        v-model="password"
        type="password"
        autocomplete="off"
        name="password"
        placeholder="请输入登录密码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{ 
                   required: true, 
                   message: '请输入登录密码' 
                 }, 
                 {
                   pattern: /^([a-zA-Z0-9]|.){6,16}$/, 
                   message: '请输入6-16位数字、字母、特殊符号'
                 }
        ]"
      />
      <div style="margin: 30px 0 0;">
        <van-button block type="primary" native-type="submit">注册并登录</van-button>
      </div>
      <div style="margin-top:20px; text-align:center">
        <span style="color:#00abeb; font-size:.24rem" @click="$router.push(`/signin?referrerRole=${$route.query.referrerRole || ''}&referrerId=${$route.query.referrerId || ''}`)"
        >已有账号，前往登录</span>
        
      </div>
      <div style="margin-top:20px; text-align:center">
        <div style=" margin-top: 20px;font-size:0.24rem; text-align: center; color:#666;">
          <van-checkbox style="display:inline-block; " v-model="checked" checked-color="#00abeb" icon-size="14px" shape="square">
            <p style="display:inline">我已阅读并同意<span style="color: #00ABEB" @click="$router.push('/blend-view/agreement?agreementType=BROKER_PROTOCOL')">《猪耳朵渠道协议》</span></p>
          </van-checkbox>
        </div>
      </div>
    </van-form>
    <div class="concat">
      <p>客服电话<a style="display: block;color: #00ABEB" :href="`tel:${serviceTel}`">{{serviceTel}}</a></p>
    </div>
  </div>
</template>
<style scoped>
  .page {height: 100%; overflow: auto;padding: 0 .6rem; box-sizing: border-box}
  .concat { line-height: 1.5; font-size: .24rem; text-align:center }
  .concat p {  padding-top:80%}
  h3 { margin:1rem 0; font-size:.46rem; }
  .van-cell::after { border:none }
  .vcode { color:#00abeb; font-size:.26rem }
  .slogan { margin-top: .3rem; font-size:.28rem; font-weight:normal; color:#999 }
  /deep/ .van-checkbox__icon {
    display: inline-block;
    vertical-align: middle
  }
  .concat { line-height: 1.5; font-size: .24rem; text-align:center }
  .concat p {  padding-top:10%}
</style>